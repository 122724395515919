<template>
  <div id="oomSlider">
    <template>
        <b-row>
        <b-col v-for="(slide1, index) in currentOom.oom.oom_entry.slice(0, 1)" :key="index" class="noPadCol" >
            <a :href="'/playerprofile/' + slide1.playerno">
            <div class="bkimage" v-bind:style="{ 'background-image': 'url(' + 'https://ocs-asia.sgp1.cdn.digitaloceanspaces.com/' + 'apga' + '/' + 'media/photos/' + slide1.playerno + '.jpg' + ')' }">
                <div class="flageSlide">
                <b-img
                    class="flag"
                    :src="
                    (config.VUE_APP_FLAG_URL + slide1.nationality)
                        | lowercase
                    "
                ></b-img>
                </div>
                <div class="content">
                </div>
                <div class="contentInfo">
                <b-row class="topOOmRow">
                    <b-col>
                    <span class="forename">
                        {{ slide1.forename }}
                        <span class="surname">
                        {{ slide1.surname }}
                        </span>
                    </span>
                    </b-col>
                    <b-col class="posOOm">
                    {{ slide1.pos }}
                    </b-col>
                </b-row>
                <hr>
                <b-row class="bottomLeftInfo">
                    <b-col>
                    <span>Winnings</span>
                    </b-col>
                    <b-col class="winningsInfo">
                    {{ slide1.oom_value }}
                    </b-col>
                </b-row>
                <b-row class="bottomRightInfo">
                    <b-col>
                    <span>Tournaments Played</span>
                    </b-col>
                    <b-col class="TournsPlayedInfo">
                    {{ slide1.tournaments_played }}
                    </b-col>
                </b-row>
                </div>
            </div>
            </a>
            <!-- <div class="gradHome"></div> -->
        </b-col>
        </b-row>
    </template>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "RightOOm",
  props: ["season", "home", "develop", "oom", "config"],
  data() {
    return {
      fields: [
        { key: "pos", label: "Pos.", class: "headerOOM" },
        { key: "name", label: "Name", class: "namehomeOom headerOOM" },
        { key: "nationality", label: "Nationality", class: "headerOOM" },
        { key: "oom_value", label: "Points", class: "headerOOM" },
        { key: "tournaments_played", label: "Played", class: "headerOOM" }
      ],
      currentOom: [],
      previousOom: [],
      prevYear: parseInt(this.season) -1,
      seasons: [],
      flag: "flag",
      loading: true,
      Year: this.season,
      year: parseInt(this.season) - 1,
    };
  },

  filters: {
    lowercase: function(value) {
      if (!value) {
        return "";
      }

      return value.toLowerCase() + ".svg";
    }
  },
  methods: {
    isMobile() {
      if( screen.width <= 768 ) {
        return true;
      }
      else {
        return false;
      }
    },
    images: function(){
        return (
          axios
            .get(
              "https://api.asia.ocs-software.com/apga/cache/" +
                'apga' +
                "/" +
                "tmticx" +
                "?randomaddssss=" +
                new Date().getTime()
            )
            .then((response) => (
              this.DEFIMAGESAMS3 = response.data.tm_params.DEFIMAGESAMS3
            ))
        ); 
    },
    getOOM: function() {
    if (this.currentOom !== 'undefined') {
        console.log('True Line 153')
        this.SeasonYear = this.season
    } else {
        console.log('false')
        this.SeasonYear = parseInt(this.season) - 1
    }
    },
    getPrevOOM: function (del) {
    if (this.$route.name === process.env.VUE_APP_ID_ADT) {
    del = process.env.VUE_APP_ID_ADT;
    } else del = process.env.VUE_APP_ID_ASIAN_TOUR;
    axios
    .get(
        process.env.VUE_APP_TIC_BASE +
        del +
        "/tmticx?field=Y&fields=seasons&fields=tm_params.season_code&randomadd=" +
        new Date().getTime()
    )
    .then(response => {
        this.seasons = response.data;
        this.DEFIMAGESAMS3 = response.data.tm_params.DEFIMAGESAMS3;
        this.loading = true;
        return axios.get(
        process.env.VUE_APP_TIC_BASE +
            del +
            "/" +
            this.prevYear +
            "/" +
            this.prevYear +
            "-ooms-oom-" +
            this.oom +
            ".json"
        );
    })
    .then(response => {
        this.currentOom = response.data;
        this.getOOM();
    })
    },
    getCurrentOOM: function (del) {
    if (this.$route.name === process.env.VUE_APP_ID_ADT) {
    del = process.env.VUE_APP_ID_ADT;
    } else del = process.env.VUE_APP_ID_ASIAN_TOUR;
    axios
    .get(
        process.env.VUE_APP_TIC_BASE +
        del +
        "/tmticx?field=Y&fields=seasons&fields=tm_params.season_code&randomadd=" +
        new Date().getTime()
    )
    .then(response => {
        this.seasons = response.data;
        this.DEFIMAGESAMS3 = response.data.tm_params.DEFIMAGESAMS3;
        this.loading = true;
        return axios.get(
        process.env.VUE_APP_TIC_BASE +
            del +
            "/" +
            this.season +
            "/" +
            this.season +
            "-ooms-oom-" +
            this.oom +
            ".json"
        );
    })
    .then(response => {
        if (Object.keys(response.data).length) {
        this.currentOom = response.data;
        this.getOOM()
        console.log('CUR')
        } else {
        this.getPrevOOM()
        console.log('PREV')
        }
    })
    }
  },
  mounted() {
    // this.getPrevOOM()
    this.images();
    this.getCurrentOOM()
    this.$refs.card.querySelector("img").onerror =
      "https://assets.asiantour.com/asian-tour/2020/05/asdummy.png";
  }
};
</script>

<style scoped>
/* ::v-deep .gradHome {
  background-color: #5897E6;
  background-image: linear-gradient(to right, #5897E6 , #A8B7C9);
  height: 10px;
  width: 94.5%;
  position: absolute;
  bottom: 0;
} */
hr {
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid #76C3BD;
  z-index: 999;
  width: 320px;
  border-top-color: #a8b7c9;
  border-width: 0.5px
}
.topOOmRow {
  width: 99.5%;
}
.posOOm {
  color: #A8B7C9;
  font-size: 49px;
  font-weight: 500;
  text-align: right;
}
.forename {
  font-size: 16px;
  font-weight: 100;
  display: grid;
  text-align: left;
  padding-left: 25px;
  color: #fff;
}
.surname {
  font-size: 26px;
  font-weight: 500;
}
.winningsInfo {
  text-align: right;
  padding-right: 30px;
}
.TournsPlayedInfo {
  text-align: right;
  padding-right: 30px;
}
.bottomLeftInfo {
  font-size: 14px;
  font-weight: 100;
  text-align: left;
  width: 94.5%;
  margin: auto;
  color: #fff;
}
.bottomRightInfo {
  font-size: 14px;
  font-weight: 100;
  text-align: left;
  width: 94.5%;
  margin: auto;
  color: #fff;
}
.noPadCol:first-child {
  padding-left: 15px;
}
.noPadCol:first-child > a > div > .content {
    left: 15px;
    width: 91.9%!important;
}
.noPadCol:last-child > div > .flageSlide {
  position: absolute;
  top: 1em;
  right: 1.5em;
}
.flageSlide {
  position: absolute;
  top: 1em;
  right: 2em;
}
.noPadCol {
  padding-left: 0;
}
.noPadCol:last-child {
  /* padding-right: 5px; */
}
.noPadCol:last-child a > div > .content {
  width: 98.8%;
}
.bkimage {
  background-repeat: no-repeat;
  background-size: cover;
  /* height: 500px; */
  height: 496px;
  background-position: center;
}
.content { 
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-top: 300px; */
  left: 0px;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  background-color: rgba(10, 63, 127, 0.7);
  /* border: 3px inset rgba(0,255,255,0.4); */
  position: absolute;
  width: 96%;
  height: 35%;
  padding: 30px;
  text-align: center;
  color: rgb(255, 255, 255);
  font-size: 20px;
  font-weight: bold;
  mix-blend-mode: multiply;
  bottom: 0;
  overflow: hidden;
} 
.contentInfo {
  position: absolute;
  z-index: 9999;
  opacity: 2;
  bottom: 15px;
  left: 0;
  right: 0;
}

a.noLinkThrough {
  pointer-events: none;
  cursor: default;
}
.oomtype {
  text-align: left;
  padding-left: 14px!important;
  text-transform: capitalize;
}
.oomvalue {
  text-align: right;
  padding-right: 21px!important;
}
img[alt~="Image"] {
  background-image: url('https://assets.asiantour.com/asian-tour/2021/11/360_F_208980504_njS12KTuZLQ3wQZaHLbKpSLFNu9rF6Hs.jpeg');
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
#carousel-2 {
  display: none;
}
a:hover {
  text-decoration: none;
}
::v-deep .carousel-control-prev {
  display: none;
}
::v-deep .carousel-control-next {
  display: none;
}
.homeslider-OOM-top {
  color: #fff;
  font-size: 20px;
  text-align: left;
  padding-left: 0px;
  white-space: nowrap;
  /* width: 145px; */
  overflow: hidden;
  text-overflow: ellipsis;
}
.homesliderNon-OOM-top {
  color: #fff;
  font-size: 20px;
  text-align: left;
  padding-left: 0px;
  /* padding-left: 8px; */
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
::v-deep .carousel-caption {
  right: 2%;
  left: 2%;
}
.card-body {
  background-color: #0b3f7e;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  color: white;
}
.card-img-top {
  height: 170px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.flag {
  width: 42px;
  text-align: center;
  /* margin: auto; */
  margin-left: 15px;
  display: block;
}
span.playedEvent {
  float: right;
  margin-right: 20px;
}
.white {
  width: 50px;
  margin: 0;
  margin-left: 40px;
}
::v-deep .col-md-6 {
  padding-left: 0;
  padding-right: 0;
  font-size: 12pt;
}
::v-deep .col-md-4 {
  display: flex;
  align-items: baseline;
  padding-top: 7px;
}
::v-deep .col-md-8 {
  padding-left: 5px;
  padding-right: 5px;
}
.cardWidth {
  padding-right: 3px;
  padding-left: 3px;
}
.flagRow {
  height: 50px;
}
.title {
  height: 47px;
}

.card {
  height: 375px;
  background-color: #f1f1f1;
  border: none;
}
.homeslider-pos {
  color: #0b3f7e;
  font-size: 30px;
  padding-top: 4px;
  text-align: center;
}
.topGpos {
  background: #fff;
  position: absolute;
  top: 0;
  left: 0;
  width: 50px;
  height: 40px;
  border-radius: 10px 0 0 0;
}
.arrow-down-slider {
  width: 0;
  height: 0;
  border-left: 25px solid transparent;
  border-right: 25px solid transparent;
  border-top: 20px solid #fff;
  position: absolute;
  bottom: -20px;
  left: 0;
}
::v-deep .carousel-indicators li {
  border-radius: 50%;
  width: 10px;
  height: 10px;
  background-color: transparent;
  border: 1px solid #ccc;
}
::v-deep .carousel-indicators {
  bottom: -15px;
}
::v-deep .carousel-indicators .active {
  background-color: #0b3f7e;
}
.developGreenText {
  color: #76c3bd;
}
::v-deep .developGreen > .card-body {
  background-color: #76c3bd;
}
::v-deep .developGreen > .carousel-indicators .active {
  background-color: #76c3bd !important;
}
::v-deep .card-img-top{
  object-fit: cover;
}
@media only screen and (max-width: 1199px) {
::v-deep .carousel-item{
    height: 450px;
}
}
@media only screen and (max-width: 1024px) {
  ::v-deep .carousel-inner {
    overflow: visible;
  }
}
@media only screen and (max-width: 990px) {
  #carousel-1 {
    /* display: none; */
  }
  #carousel-2 {
    display: block;
  }
  .card-img-top {
    width: 100%;
    height: auto;
    min-height: 225px;
    border-radius: 10px 10px 0 0 !important;
    max-height: 100%;
    max-height: 220px!important;
  }
  .oomtype {
    text-align: left;
    padding-left: 25px!important;
    text-transform: capitalize;
  }
  .oomvalue {
    text-align: right;
    padding-right: 50px!important;
  }
  .col-md-6.eventsPlayed.col {
    text-align: left;
    padding-left: 25px;
  }
  .mobileWidth {
    max-width: 100%!important;
  }
  .homeslider-OOM-top{
    color: #fff;
    font-size: 20px;
    text-align: left;
    padding-left: 30px;
    white-space: nowrap;
    /* width: 145px; */
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .flag {
    width: 50px;
    margin: auto;
    margin-left: 25px;
    display: block;
  }
  span.playedEvent {
    float: right;
    margin-right: 50px;
  }
  .homesliderNon-OOM-top {
    color: #fff;
    font-size: 20px;
    text-align: left;
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: 15px;
  }
  .card {
    height: 100%;
    background-color: #f1f1f1;
    border: none;
  }
}
@media only screen and (max-width: 425px) {
  ::v-deep .cardPad {
    width: 100%;
  }
  ::v-deep .noPadCol:last-child a > div > .content {
    width: 97.5%;
  }
  .mobileWidth {
    max-width: 100%!important;
  }
  .homeslider-OOM-top{
    color: #fff;
    font-size: 20px;
    text-align: left;
    padding-left: 30px;
    white-space: nowrap;
    /* width: 145px; */
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .flag {
    width: 50px;
    margin: auto;
    margin-left: 25px;
    display: block;
  }
  span.playedEvent {
    float: right;
    margin-right: 20px;
  }
  .oomvalue {
    text-align: right;
    padding-right: 20px!important;
  }
  .homesliderNon-OOM-top {
    color: #fff;
    font-size: 20px;
    text-align: left;
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: 15px;
  }
  .card {
    height: 100%;
    background-color: #f1f1f1;
    border: none;
  }
}
</style>