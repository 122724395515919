<template>
    <div>
        <div v-for="(tourn, index) in tm_params.slice(5,6)" :key="index">
            <b-row class="image">
                <b-col class="imagewidth" v-bind:style="{ 'background-image': 'url(' + 'https://ocs-asia.sgp1.cdn.digitaloceanspaces.com/apga/courses/' + season + '/' + tourn.course_code +'/' + 'File_1.jpg' + ')' }">
                </b-col>
            </b-row>  
            <div class="gradHome"></div>
            <div class="logo">
                <div class="outerLogo">
                    <div class="innerLogo">
                        <img class="logoImage" :src="tourn.sponsor_logo">
                    </div>
                </div>
            </div>
            <b-row class="nextbtnColor">
                <b-col>
                    <p class="dicDates">{{tourn.dic_dates}}</p>
                    <p class="fullName">{{tourn.full_name}}</p>
                    <p class="course">{{tourn.course}}</p>
                </b-col>
            </b-row> 
            <b-row class="NextBottomBlie">
                <b-col>
                    <div data-v-372d672c="" class="FullListingBtn">
                        <a data-v-372d672c="" href="" class="btn btn-secondary" target="_self">Tournament Info </a>
                    </div>
                </b-col>
            </b-row>   
        </div>
    </div>
</template>

<script>
import axios from "axios";
export default {
  name: "Next Tourn",
  props: ["season", 'config'],
  components: {
  },
  data() {
    return {
      tm_params: [],
    };
  },
  methods: {

  },
  mounted() {
    setTimeout(() => {
    axios //add API Call
      .get(
        process.env.VUE_APP_TIC_BASE +
          'apga' +
          "/" +
          this.season +
          "/tmticx?randomadd=" +
          new Date().getTime()
      ) // Page number changed on each page based on JSON page id you are calling
      .then(response => {
        this.tm_params = response.data.tournaments.tournaments_entry
      })
    }, 1000)
  },
};
</script>

<style scoped>
.innerLogo {
    background-color: #fff;
    height: 120px;
    width: 120px;
    position: absolute;
    border-radius: 105px;
    top: 10.3em;
    left: 123px;
    right: 55px;
}
img.logoImage {
    width: 75px;
    text-align: center;
    margin: auto;
    display: block;
    position: relative;
    top: 37px;
}
.dicDates {
    color: #5897E6;
    font-weight: 200;
    font-size: 20px;
    margin-bottom: 5px;
}
.gradHome {
    background-color: #5897E6;
    background-image: linear-gradient(to right, #5897E6, #A8B7C9);
    height: 17px;
}
.fullName {
    font-size: 22px !important;
    color: #fff;
    margin-bottom: 5px;
}
.course {
    font-size: 22px !important;
    color: #fff; 
    font-weight: 100;
}
.image {
    margin-left: 0;
    margin-right: 0px;
}
.imagewidth {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 250px;
}
.nextbtnColor {
    background-color: #093e7c;
    height: 250px;
    margin-left: 0;
    margin-right: 0px;
    padding: 20px;
    padding-top: 80px;
}
.FullListingBtn > a.btn {
    background-color: #042F62;
    border-radius: 0;
    color: #fff !important;
    width: 100%;
    height: 59px;
    font-weight: 500;
    font-size: 14px;
    text-transform: uppercase;
    line-height: 3.1;
    border-color: transparent;
}
.FullListingBtn > a.btn:hover {
    background-color: #5897E6;
    border-radius: 0;
    color: #fff !important;
    width: 100%;
    height: 59px;
    font-weight: 500;
    font-size: 14px;
    text-transform: uppercase;
    line-height: 3.1;
    border-color: transparent;
}
@media only screen and (max-width: 1200px) {
    .nextbtnColor {
        background-color: #093e7c;
        height: auto;
        margin-left: 0;
        margin-right: 0px;
        padding: 20px;
        padding-top: 30px;
    }
    .innerLogo {
        display: none;
    }
}
</style>
