<template>
  <div v-if="loading === true">
    <b-container>
      <h3
        v-if="home !== true"
        class="title oomMob"
      >
        Order of Merit
      </h3> 
      <h2 v-else class="SectionTitle oomHeader"> {{currentOom.season_code}} Order Of Merit</h2>
      <div class="slider" v-if="home === true">
        <OomSlider
          :currentOom="currentOom"
          :previousOom="previousOom"
          :season="season"
          :develop="develop"
          :config="config"
          :DEFIMAGESAMS3="DEFIMAGESAMS3"
        />
      </div>
      <template v-if="home !== true">
        <template v-if="currentOom !== null">
          <div class="table-responsive">
            <b-table
              :items="currentOom.oom.oom_entry"
              :class="{ developGreen: develop === config.VUE_APP_ID_ADT }"
              :fields="fields"
              :per-page="10"
              custom-foot
            >
              <template #cell(nationality)="row">
                <span>
                  <img
                    :class="flag"
                    :src="
                      (config.VUE_APP_FLAG_URL + row.item.nationality) | lowercase
                    "
                  />
                </span>
              </template>
              <template #cell(name)="row">
                <template v-if="row.item.profile == 'Y'">
                  <a :href="'/playerprofile/' + row.item.playerno">
                    {{ row.item.name }}
                  </a>
                </template>
                <template v-else>
                  <span class="noLink">
                    {{ row.item.name }}
                  </span>
                </template>
                <span v-if="row.item.sponsored === 'Y'">
                  <img :src="config.VUE_APP_TITLEIST" class="titliestMob">
                </span>
              </template>
              <template slot="custom-foot">
                <td class="listing fullListingOOM" colspan="5">
                  <b-nav-item :to="'/oom/' + 'rankings'">
                    Full Listings
                  </b-nav-item>
                </td>
              </template>
            </b-table>
          </div>
        </template>
        <template v-else>
          <div class="table-responsive">
            <b-table
              :class="{ developGreen: develop === config.VUE_APP_ID_ADT }"
              :items="previousOom.oom.oom_entry"
              :fields="fields"
              :per-page="10"
            >
              <template #cell(nationality)="row">
                <span>
                  <img
                    :class="flag"
                    :src="
                      (config.VUE_APP_FLAG_URL + row.item.nationality) | lowercase
                    "
                  />
                </span>
              </template>
              <template #cell(name)="row">
                <template v-if="row.item.profile == 'Y'">
                  <a :href="'/playerprofile/' + row.item.playerno">
                    {{ row.item.name }}
                  </a>
                </template>
                <template v-else>
                  <span class="noLink">
                    {{ row.item.name }}
                  </span>
                </template>
                <span v-if="row.item.sponsored === 'Y'">
                  <img :src="config.VUE_APP_TITLEIST" class="titliestMob">
                </span>
              </template>
              <template slot="custom-foot">
                <td class="listing fullListingOOM" colspan="5">
                  <b-nav-item :to="'/oom/' + 'rankings'">
                    Full Listings
                  </b-nav-item>
                </td>
              </template>
            </b-table>
          </div>
        </template>
      </template>
    </b-container>
  </div>
</template>

<script>
import axios from "axios";
import OomSlider from "@/components/oomSlider.vue";

export default {
  name: "smallOom",
  props: ["season", "home", "develop", "oom", "config"],
  components: {
    OomSlider
  },
  data() {
    return {
      fields: [
        { key: "pos", label: "Pos.", class: "headerOOM" },
        { key: "name", label: "Name", class: "namehomeOom headerOOM" },
        { key: "nationality", label: "Nationality", class: "headerOOM" },
        { key: "oom_value", label: "Points", class: "headerOOM" },
        { key: "tournaments_played", label: "Played", class: "headerOOM" }
      ],
      currentOom: [],
      previousOom: [],
      prevYear: parseInt(this.season) -1,
      seasons: [],
      flag: "flag",
      loading: true,
      Year: this.season,
      year: parseInt(this.season) - 1,
    };
  },

  filters: {
    lowercase: function(value) {
      if (!value) {
        return "";
      }

      return value.toLowerCase() + ".svg";
    }
  },
  methods: {
      getOOM: function() {
        if (this.currentOom !== 'undefined') {
          console.log('True Line 153')
          this.SeasonYear = this.season
        } else {
          console.log('false')
          this.SeasonYear = parseInt(this.season) - 1
        }
      },
      getPrevOOM: function (del) {
      if (this.$route.name === process.env.VUE_APP_ID_ADT) {
        del = process.env.VUE_APP_ID_ADT;
      } else del = process.env.VUE_APP_ID_ASIAN_TOUR;
      axios
        .get(
          process.env.VUE_APP_TIC_BASE +
            del +
            "/tmticx?field=Y&fields=seasons&fields=tm_params.season_code&randomadd=" +
            new Date().getTime()
        )
        .then(response => {
          this.seasons = response.data;
          this.DEFIMAGESAMS3 = response.data.tm_params.DEFIMAGESAMS3;
          this.loading = true;
          return axios.get(
            process.env.VUE_APP_TIC_BASE +
              del +
              "/" +
              this.prevYear +
              "/" +
              this.prevYear +
              "-ooms-oom-" +
              this.oom +
              ".json"
          );
        })
        .then(response => {
          this.currentOom = response.data;
          this.getOOM();
        })
      },
      getCurrentOOM: function (del) {
      if (this.$route.name === process.env.VUE_APP_ID_ADT) {
        del = process.env.VUE_APP_ID_ADT;
      } else del = process.env.VUE_APP_ID_ASIAN_TOUR;
      axios
        .get(
          process.env.VUE_APP_TIC_BASE +
            del +
            "/tmticx?field=Y&fields=seasons&fields=tm_params.season_code&randomadd=" +
            new Date().getTime()
        )
        .then(response => {
          this.seasons = response.data;
          this.DEFIMAGESAMS3 = response.data.tm_params.DEFIMAGESAMS3;
          this.loading = true;
          return axios.get(
            process.env.VUE_APP_TIC_BASE +
              del +
              "/" +
              this.season +
              "/" +
              this.season +
              "-ooms-oom-" +
              this.oom +
              ".json"
          );
        })
        .then(response => {
          if (Object.keys(response.data).length) {
            this.currentOom = response.data;
            this.getOOM()
            console.log('CUR')
          } else {
            this.getPrevOOM()
            console.log('PREV')
          }
        })
      }
  },
  mounted() {
    // this.getPrevOOM()
    this.getCurrentOOM()
  }
};
</script>

<style scoped>
h2.SectionTitle.oomHeader {
  font-size: 23px;
  font-weight: 500;
  color: #042F62;
  padding-bottom: 25px;
  padding-left: 8px;
}
span.noLink {
  color: black;
}
@media only screen and (max-width: 990px) {
  h2.SectionTitle.oomHeader {
    padding-left: 0px!important;
  }
  .oomMob {
    /* display: none; */
    padding-left: 10px;
    padding-bottom: 20px;
    margin-top: 20px;
  }
  tfoot > td {
    padding: 0.75rem !important;
  }
}
::v-deep td.fullListingOOM > li {
  padding-top: 0px;
  display: block !important;
}
::v-deep td.fullListingOOM {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  font-size: 12pt;
}
::v-deep td.headerOOM {
  padding-top: 7px;
  padding-bottom: 7px;
  font-size: 15px;
}
::v-deep th.headerOOM {
  font-size: 13px;
  padding: 0.45rem !important;
}
::v-deep td.namehomeOom {
  text-align: left;
  color: #337ab7;
  padding-left: 8px;
}
::v-deep td.namehomeOom > a {
  color: #337ab7;
}
::v-deep th.namehomeOom {
  text-align: left;
}
::v-deep thead {
  color: white;
  background-color: #133f7b;
  border-radius: 10px;
}

::v-deep .developGreen > thead {
  background-color: #76c3bd !important;
}
::v-deep .developGreen > tfoot {
  background-color: #76c3bd !important;
}
.developGreen {
  color: #76c3bd !important;
}
::v-deep .developGreen > tbody > tr > td {
  color: black !important;
}

::v-deep .table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 0;
}

::v-deep tfoot {
  background-color: #133f7b;
  color: white !important;
  border: none;
}

::v-deep th {
  text-align: center;
  border: none;
}
::v-deep th:first-child {
  border-top-left-radius: 15px;
}
::v-deep th:last-child {
  border-top-right-radius: 15px;
}
::v-deep tfoot > td:first-child {
  border-bottom-left-radius: 15px;
}
::v-deep tfoot > td:last-child {
  border-bottom-right-radius: 15px;
}
::v-deep tfoot > td {
  padding: 0.75em 26em;
}
::v-deep tfoot > td > li > a {
  color: white !important;
}
::v-deep td {
  text-align: center;
  padding: 0;
}
.listing > a {
  color: white;
}

.flag {
  width: 30px;
  border: 0.25px solid #e2e1e1
}
::v-deep tr:nth-child(even) {
  background-color: #fff;
}
.slider {
  padding-bottom: 5%;
}

#oomPage {
  background-color: #ebebf2;
}
.topSec {
  padding-top: 5%;
  padding-bottom: 5%;
}

.title {
  color: #0b3f7e;
}

.topSecrow {
  padding-top: 2%;
}
.logo {
  width: 35%;
  float: right;
}
@media only screen and (max-width: 1024px) {
  ::v-deep td:nth-child(3) {
    display: none;
  }
  ::v-deep th:nth-child(3) {
    display: none;
  }
  ::v-deep table {
    font-size: 1rem;
  }
}
@media only screen and (max-width: 768px) {
  ::v-deep td:nth-child(3) {
    display: none;
  }
  ::v-deep th:nth-child(3) {
    display: none;
  }
  ::v-deep table {
    font-size: 0.7rem;
  }
}
@media only screen and (max-width: 480px) {
  .flag {
    width: 30px;
  }
}
</style>